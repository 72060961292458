<template>
  <div>
    <div class="row" ref="viewBox">
      <p class="show-page">
        Show 1 - {{ flag&&offset + limit + countNumber > total + countNumber ? total + countNumber : (offset + limit + countNumber) | currencyPrice("", "", "0") }} of {{ flag?(total + countNumber):(total + countNumber) | currencyPrice("", "", "0") }} listings
      </p>

    <!-- <p class="show-page">{{ total  | currencyPrice("", "", "0") }} listings</p> :height="tableMaxHeight"-->
    <el-button class="primary-button" @click="showAllPage">Show on One Page</el-button> 
    </div>
    <div  class="w-table" :class="{ 'w-table_moving': dragState.dragging }">
      <el-table
        ref="multipleTable"
        :data="datas"
        tooltip-effect="dark"
        :header-cell-class-name="headerCellClassName"
        @selection-change="checked"
        @sort-change="sort"
        :default-sort="orderContent"
        style="width: 100%;"
        :height="tableMaxHeight" 
        v-el-table-infinite-scroll="load" 
        :row-class-name="settingStyle"
      >
        <el-table-column class="selection" type="selection" width="45" ></el-table-column>
        <el-table-column width="45" class-name="caiteria">
          <template slot-scope="scope">
            <img @click="propertyCalendar(scope.row.listing_id)" src="../../../assets/icon/ico-calendar.svg"/>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(col, index) in tableHeader"
          :key="index"
          :prop="col.prop"
          :label="col.label"
          :width="col.width"
          :min-width="col.minWidth"
          :type="col.type"
          :sortable="col.sortable"
          :header-align="col.headerAlign"
          :column-key="index.toString()"
          :render-header="renderHeader"
        >
          <template slot-scope="scope">
            <span v-if="col.label == 'KEY'"  @click="propertyLocation(scope.row.listing_id)" >{{ scope.row.key_number }}</span >
            <img v-if="col.label == 'IMAGE'" @click="propertyPhoto(scope.row.listing_id)" v-lazy="scope.row.url" style="height: 70px; width: 100%" />
            <span v-if="col.label == 'ADDRESS'" style="word-wrap: break-word; width: 100%; display: inline-block" @click="propertyLocation(scope.row.listing_id)" >{{ scope.row.address }}</span >
            <span v-if="col.label == 'AREA'" @click="propertyLocation(scope.row.listing_id)">{{ scope.row.area_name }}</span>
            <span v-if="col.label == 'BRS'" @click="propertyBedroomsAndBathrooms(scope.row.listing_id)" >{{ scope.row.bedroom_number }}</span >
            <span v-if="col.label == 'BATHS'" @click="propertyBedroomsAndBathrooms(scope.row.listing_id)">{{ scope.row.bathroom_number }}</span>
            <span v-if="col.label == 'CAPACITY'" @click="propertyGeneral(scope.row.listing_id)">{{ scope.row.capacity }}</span>
            <span v-if="col.label == 'OWNER'" @click="propertyGeneral(scope.row.listing_id)">{{ scope.row.owner_last_name }}</span>
            <span v-if="col.label == 'TURNOVER'" @click="propertyGeneral(scope.row.listing_id)">{{ scope.row.turnover_day }}</span>
            <span v-if="col.label == 'LAST UPDATE'" @click="propertyDetail(scope.row.listing_id)">{{ scope.row.calendar_updated_at?scope.row.calendar_updated_at:null | dateFormat }}</span>
            <div v-if="col.label == 'PRICE on SEARCH CRITERIA'" @click="propertyCalendar(scope.row.listing_id)"> 
            <p v-if="scope.row.rates.length===0">
              <span v-if="scope.row.year_max_price">Max Price | {{scope.row.year_max_price | currencyPrice('$ ')}}</span>
              <span v-else-if="!scope.row.year_max_price&&scope.row.max_price">Max Price | {{scope.row.max_price | currencyPrice('$ ')}}</span>
            </p>
            <div v-else>
              <div  v-for="(item, index) in scope.row.rates" :key="index">
                <div v-if="index < 3" class="search_price">
                  <p
                    v-if=" scope.row.turnover_day === 'Saturday' && searchInfor.start_date != getDate(item.from_date, 6)">
                    {{ item.from_date | dateFormatAdd(-1) }} -
                    {{ item.from_date | dateFormatAdd(6) }} |
                    <b>${{ item.amount | currencyPrice }}</b>
                  </p>
                  <p v-else-if=" scope.row.turnover_day === 'Friday' && searchInfor.start_date != getDate(item.from_date, 5)">
                    {{ item.from_date | dateFormatAdd(-2) }} -
                    {{ item.from_date | dateFormatAdd(5) }} |
                    <b>${{ item.amount | currencyPrice }}</b>
                  </p>
                  <p v-else-if=" scope.row.turnover_day === 'Thursday' && searchInfor.start_date != getDate(item.from_date, 4)">
                    {{ item.from_date | dateFormatAdd(-3) }} -
                    {{ item.from_date | dateFormatAdd(4) }} |
                    <b>${{ item.amount | currencyPrice }}</b>
                  </p>
                  <p v-else-if="scope.row.turnover_day === 'Wednesday' && searchInfor.start_date != getDate(item.from_date, 3) ">
                    {{ item.from_date | dateFormatAdd(-4) }} -
                    {{ item.from_date | dateFormatAdd(3) }} |
                    <b>${{ item.amount | currencyPrice }}</b>
                  </p>
                  <p v-else-if=" scope.row.turnover_day === 'Tuesday' && searchInfor.start_date != getDate(item.from_date, 2)">
                    {{ item.from_date | dateFormatAdd(-5) }} -
                    {{ item.from_date | dateFormatAdd(2) }} |
                    <b>${{ item.amount | currencyPrice }}</b>
                  </p>
                  <p v-else-if=" scope.row.turnover_day === 'Monday' && searchInfor.start_date != getDate(item.from_date, 1)">
                    {{ item.from_date | dateFormatAdd(-6) }} -
                    {{ item.from_date | dateFormatAdd(1) }} |
                    <b>${{ item.amount | currencyPrice }}</b>
                  </p>
                  <p v-else-if="(scope.row.turnover_day === 'Sunday' ||scope.row.turnover_day === null) &&searchInfor.start_date != getDate(item.from_date, 7)">
                    {{ item.from_date | dateFormat }} -
                    {{ item.from_date | dateFormatAdd(7) }} |
                    <b>${{ item.amount | currencyPrice }}</b>
                  </p>
                </div>
              </div>
            </div>
            </div>
             <span v-if="col.label == 'LATEST LEASED BY'" @click="propertyRequirement(scope.row.listing_id)">{{ scope.row.latest_leased_info?scope.row.latest_leased_info.leased_by:'' }}</span>

          </template>
        </el-table-column>
         <!-- <p slot="append" style="text-align:center; line-height:50px;" v-loading="loading"><a href="javascript:;"  @click="load()" class="blue01">点击加载更多</a></p> -->
      </el-table>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import elTableInfiniteScroll from 'el-table-infinite-scroll';
export default {
  directives: { 'el-table-infinite-scroll': elTableInfiniteScroll },
  props: ["offset", "total", "searchInfor", "data", "header", "option","limit", "flag"],
  data() {
    return {
      // orderContent: { prop: "calendar_updated_at", order: "descending" }, // table默认的排序
      orderContent: { prop: "", order: "" }, 
      countNumber: 0,
      datas: [],
      tableHeader: this.header,
      loading: false,
      dragState: {
        start: -1, // 起始元素的 index
        end: -1, // 结束元素的 index
        move: -1, // 移动鼠标时所覆盖的元素 index
        dragging: false, // 是否正在拖动
        direction: undefined, // 拖动方向
      },
      flags:false,
    };
  },
  methods: {
    settingStyle({ row}){
       if (row.not_renting_year) {
        return 'red'
      } else {
        if (row.priority===1) {
        return 'blue'
      } else if (row.priority===2) {
        return 'green'}
      }
    },
    load(){
      if(this.$refs.viewBox.scrollTop+ this.$refs.viewBox.offsetHeight+20 >= this.$refs.viewBox.scrollHeight){
          this.$emit("load");
       }
    },
    topClick(){
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
    },
    getDate(date, day) {
      return dayjs(date).add(day, "day").format("YYYY-MM-DD");
    },

    propertyLocation(index) {
      // window.open( "/property/" + index + "/location");
      let routeData = this.$router.resolve({name: "property-location", params: { id: index }});
      window.open(routeData.href, '_blank');
    },
    propertyDescriptions(index) {
        // window.open("/property/" + index + "/descriptions");
        this.$router.push({ name: "property-descriptions", params: { id: index } });
        window.open(routeData.href, '_blank');
    },
    propertyGeneral(index) {
        // window.open("/property/" + index + "/general");
        let routeData =  this.$router.resolve({ name: "property-general", params: { id: index } });
        window.open(routeData.href, '_blank');
    },
    propertyAmenities(index) {
        // window.open("/property/" + index + "/amenities");
        let routeData =  this.$router.resolve({ name: "property-amenities", params: { id: index } });
        window.open(routeData.href, '_blank');
    },
    propertyBedroomsAndBathrooms(index) {
        // window.open("/property/" + index + "/bedroomsandbathrooms");
        let routeData =  this.$router.resolve({ name: "property-bedroomsandbathrooms", params: { id: index } });
        window.open(routeData.href, '_blank');
    },
    
    // 跳转到房子的日历页面
    propertyCalendar(index) {
      this.$store.commit("setPropertyInfor", true);
      this.$store.commit("setPropertyReuirement", false);
      this.$store.commit("setPropertyCalendar", false);
      this.$store.commit("setPropertyPhoto", false);
      // window.open("/property/" + index + "/calendar");
      let routeData =  this.$router.resolve({ name: "property-calendar", params: { id: index } });
      window.open(routeData.href, '_blank');
    },
    // 跳转到房子的图片页面
    propertyPhoto(index) {
      this.$store.commit("setPropertyInfor", true);
      this.$store.commit("setPropertyReuirement", false);
      this.$store.commit("setPropertyCalendar", false);
      this.$store.commit("setPropertyPhoto", false);
    //  window.open("/property/"+index+"/photo");
      let routeData =  this.$router.resolve({ name: "property-photo", params: { id: index } });
      window.open(routeData.href, '_blank');
    },
    // 跳转到房子的预定要求页面
    propertyRequirement(index) {
      this.$router.push({
        name: "property-requirement",
        params: { id: index },
      });
    },
    checked(item) {
      this.$emit("checked", item);
    },
    sort(item) {
      this.$emit("sort", item);
    },
    headerCellClassName({ column, columnIndex }) {
      let active = columnIndex - 2 === this.dragState.end ? `darg_active_${this.dragState.direction}` : "";
      let start = columnIndex - 2 === this.dragState.start ? `darg_start` : "";
      return `${active} ${start}`;
    },
    renderHeader(createElement, { column }) {
      return createElement(
        "div",
        {
          class: ["thead-cell"],
          on: {
            mousedown: ($event) => {
              this.handleMouseDown($event, column);
            },
            mouseup: ($event) => {
              this.handleMouseUp($event, column);
            },
            mousemove: ($event) => {
              this.handleMouseMove($event, column);
            },
          },
        },
        [
          // 添加 <a> 用于显示表头 label
          createElement("a", column.label),
          // 添加一个空标签用于显示拖动动画
          createElement("span", {
            class: ["virtual"],
          }),
        ]
      );
    },
    // 按下鼠标开始拖动
    handleMouseDown(e, column) {
      this.dragState.dragging = true;
      this.dragState.start = parseInt(column.columnKey);
      // 给拖动时的虚拟容器添加宽高
      let table = document.getElementsByClassName("w-table")[0];
      let virtual = document.getElementsByClassName("virtual");
      for (let item of virtual) {
        item.style.height = table.clientHeight - 1 + "px";
        item.style.width = item.parentElement.parentElement.clientWidth + "px";
      }
      document.addEventListener("mouseup", this.handleMouseUp);
    },
    // 鼠标放开结束拖动
    handleMouseUp() {
      this.dragColumn(this.dragState);
      // 初始化拖动状态
      this.dragState = {
        start: -9,
        end: -9,
        dragging: false,
        direction: undefined,
      };
      document.removeEventListener("mouseup", this.handleMouseUp);
    },
    // 拖动中
    handleMouseMove(e, column) {
      if (this.dragState.dragging) {
        let index = parseInt(column.columnKey); // 记录起始列
        if (index - this.dragState.start !== 0) {
          this.dragState.direction =
            index - this.dragState.start < 0 ? "left" : "right"; // 判断拖动方向
          this.dragState.end = parseInt(column.columnKey);
        } else {
          this.dragState.direction = undefined;
        }
      } else {
        return false;
      }
    },
    // 拖动易位
    dragColumn({ start, end, direction }) {
      let tempData = [];
      let left = direction === "left";
      let min = left ? end : start - 1;
      let max = left ? start + 1 : end;
      for (let i = 0; i < this.tableHeader.length; i++) {
        if (i === end) {
          tempData.push(this.tableHeader[start]);
        } else if (i > min && i < max) {
          tempData.push(this.tableHeader[left ? i - 1 : i + 1]);
        } else {
          tempData.push(this.tableHeader[i]);
        }
      }
      localStorage.removeItem("HeaderDatas");
      localStorage.setItem("HeaderDatas", JSON.stringify(tempData));
      this.tableHeader = tempData;
    },
    toggleSelection(rows) {
      rows.forEach((row) => {
        this.$nextTick(() => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      });
    },
    init(newV) {
      // let arr = [];
      // arr = arr.concat(newV);
      // this.datas = [];
      if (this.$store.state.propertiesCheckeds.length != 0) {
        this.toggleSelection(this.$store.state.propertiesCheckeds);
      }
      //   this.datas = this.datas.concat(this.$store.state.propertiesCheckeds);
      //   if (arr.length != 0) {
      //     //  删除重复的房子
      //     this.$store.state.propertiesCheckeds.forEach((i) => {
      //       arr.forEach((item, index) => {
      //         if (item.listing_id == i.listing_id) {
      //           arr.splice(index, 1);
      //         }
      //       });
      //     });
      //   }
      //   this.datas = this.datas.concat(arr);
        this.datas = newV;
        // this.countNumber = parseInt( this.limit - arr.length + Number(this.$store.state.propertiesCheckeds.length) );
        
      // } else {
      //   this.countNumber = 0;
      //   this.datas = arr;
      // }
    },
    showAllPage(){
      this.$emit("showAllPage")
    },
     // 节流
    throttle(fn, delay, atleast) {
      /**函数节流方法
       @param Function fn 延时调用函数
       @param Number dalay 延迟多长时间
       @param Number atleast 至少多长时间触发一次
       @return Function 延迟执行的方法*/
      let timer = null;
      let previous = null;
      return function () {
        var now = +new Date();
        if (!previous) previous = now;
        if (atleast && now - previous > atleast) {
          fn();
          // 重置上一次开始时间为本次结束时间
          previous = now;
          clearTimeout(timer);
        } else {
          clearTimeout(timer);
          timer = setTimeout(function () {
            fn();
            previous = null;
          }, delay);
        }
      };
    },
  },
  watch: {
    header(val) {
      this.tableHeader = val;
    },
    data(newV) {
      this.init(newV);
    },
  },
  created() {
   
    this.datas = [];
     if(JSON.parse(localStorage.getItem("HeaderDatas"))){
       this.tableHeader = JSON.parse(localStorage.getItem("HeaderDatas"));
      //  var keyMap = {
      //       width: "minWidth",
      //     };//循环数组，将width换成minWidth
      //     for (var i = 0; i < this.tableHeader.length; i++) {
      //       var obj = this.tableHeader[i];
      //       for (var key in obj) {
      //         var newKey = keyMap[key];
      //         if (newKey) {
      //           obj[newKey] = obj[key];
      //           delete obj[key];
      //         }
      //       }
      //     }
      //     if(this.tableHeader.length===11){
      //       let obj = {
      //         prop: "",
      //         label: "LATEST LEASED BY",
      //         minWidth: "160px",
      //         sortable: "",
      //       }
      //       this.tableHeader.push(obj)
      //     }
    }
    if (this.$store.state.propertiesCheckeds.length != 0) {
      this.datas.push(this.$store.state.propertiesCheckeds);
    }
    if (this.$route.query.ordering&&this.$route.query.ordering!='not_renting_year,priority,-calendar_updated_at') {
      let arr = this.$route.query.ordering.split(",")
      if (arr[arr.length-1].indexOf("-") != -1) {
        this.orderContent.prop = arr[arr.length-1].split( "-" )[1];
        this.orderContent.order = "descending";
      } else {
        this.orderContent.prop = arr[arr.length-1];
        this.orderContent.order = "ascending";
      }
    }else{
      this.orderContent.prop = 'calendar_updated_at';
      this.orderContent.order = "descending";
    }

    this.$nextTick(() => {
      this.$refs.multipleTable.bodyWrapper.scrollTop = this.$refs.multipleTable.bodyWrapper.scrollHeight;
    });
  },
  computed: {
    tableMaxHeight() {
      return window.innerHeight + 100 +"px";
    },
  },
  mounted(){
    this.$refs.viewBox.addEventListener('scroll', this.throttle(this.load,2000),false)
  }
};
</script>
<style lang="scss">
@import "./ListingTable.scss";
@import "../../../css/button.scss";
</style>
