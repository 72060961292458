<template>
  <div class="top_search">
    <div class="title_container">
      <p class="row title">
        <img src="../../../assets/icon/rental-list.svg" alt="" /><span>Rentals Listings</span>
      </p>
    </div>
    <div class="properties-top-row row">
      <div class="properties-filter">
        <div class="row properties-filter-infor">
          <div class="setting-width">
            <el-popover placement="bottom" popper-class="popper" trigger="click" v-model="showPrice">
              <div class="row slider-container">
                <p class="form-item-title">$0</p>
                <el-slider class="price-slider" :v-modal="priceValue" range :max="30000" :step="5000"
                  @change="priceChange" />
                <p class="form-item-title">No Max</p>
              </div>
              <p class="form-item-title">Selected Range: $ {{ data.min_price ? data.min_price : 0 }} - {{ data.max_price && data.max_price != 30000 ?
                  '$ ' + data.max_price : 'No Max'
              }}</p>
              <div style="display: flex; flex-direction: row; justify-content: flex-end;">
                <el-button style="background:#1C4A5E ; color: white" @click="confirmPrice">Confirm</el-button>
              </div>
              <div slot="reference" class="reference show-price">
                <el-button style="width: 100%; padding: 8.5px 5px;" v-if="!data.min_price && !data.max_price">
                  <p class="row btn-row" style="color: #c0c4cc">
                    <img src="../../../assets/icon/price.svg" />
                    <span>Price Range</span>
                  </p>
                </el-button>
                <el-button v-else class="row btn-row"
                  style="color: #c0c4cc; width: 100%; padding: 12px 5px; justify-content: center;">
                  <span style="color: #606266 !important">{{
                      data.min_price
                        ? data.min_price
                        : 0 | sliderCurrencyPrice("$ ", 0)
                  }}&#x3000; - &#x3000;{{
                    data.max_price
                      ? data.max_price
                      : 30000 | sliderCurrencyPrice("$ ", 0)
                }}</span>
                </el-button>
              </div>
            </el-popover>
            <el-input class="distance-top-margin" v-model="data.address" placeholder="Street address"
              @change="addressFilter" @keyup.enter.native="addressFilter">
              <template slot="prefix">
                <img src="../../../assets/icon/street.svg" class="prefix_icon" />
              </template>
            </el-input>

          </div>
          <div class="setting-width" style="margin: 0 10px 0 10px">
            <el-date-picker style="width: 100%" v-model="data.dates" :default-value="mouthdate.date" type="daterange"
              range-separator="" start-placeholder="Date Range" format="MM/dd/yyyy" value-format="yyyy-MM-dd"
              @change="dateFilter">
            </el-date-picker>
            <el-select v-model="data.area" @change="areaFilter" multiple placeholder="Neighborhood" collapse-tags
              class="distance-top-margin" style="width: 100%">
              <template slot="prefix">
                <img src="../../../assets/icon/street.svg" class="prefix_icon" />
              </template>
              <el-checkbox style="margin: 10px 20px" :indeterminate="isIndeterminate" v-model="checkAll"
                @change="handleCheckAllChange">Select All</el-checkbox>
              <el-option v-for="item in areas" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
          <div class="setting-width" style="margin: 0 10px 0 10px">
            <el-popover placement="bottom" title="" width="220" trigger="click" v-model="showAmenities">
              <div class="row">
                <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 10px; width: 100%;">
                  <el-checkbox v-model="data.air_conditioning_centeral">Air Conditioning: central</el-checkbox>
                  <el-checkbox v-model="data.air_conditioning_minisplit">Air Conditioning: mini split</el-checkbox>
                  <el-checkbox v-model="data.air_conditioning_windowunits">Air Conditioning: window units</el-checkbox>
                  <el-checkbox v-model="data.pets_yes">Pets: yes</el-checkbox>
                  <el-checkbox v-model="data.pets_no">Pets: no</el-checkbox>
                  <el-checkbox v-model="data.pets_askowner">Pets: ask owner</el-checkbox>
                  <el-checkbox v-model="data.pool_private">Pool: private</el-checkbox>
                  <el-checkbox v-model="data.pool_community">Pool: community</el-checkbox>
                  <el-checkbox v-model="data.walk_to_beach">Walk to the beach</el-checkbox>
                  <el-checkbox v-model="data.waterfront">Waterfront</el-checkbox>
                  <el-checkbox v-model="data.water_views">Water views</el-checkbox>
                  <el-button style="background:#1C4A5E ; color: white; width: 100%;" @click="amenitiesFilter">Done</el-button>
                </div>
              </div>
              <el-button slot="reference" class="reference">
                <p class="row btn-row" style="color: #CCC4CC;">
                  <span>Amenities & Features</span>
                </p>
              </el-button>
            </el-popover>
            <el-input class="distance-top-margin" v-model="data.owner_last_name" @change="ownerFilter"
              @keyup.enter.native="ownerFilter" placeholder="Owner">
              <template slot="prefix">
                <img src="../../../assets/icon/owner.svg" class="prefix_icon" />
              </template>
            </el-input>
          </div>
          <div class="setting-width" style="margin: 0 10px 0 10px">
            <div class="row">
              <el-select v-model="data.nights" placeholder="Nights" @change="consecutiveFilter" clearable>
                <el-option v-for="item in 99" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
              <el-select v-model="data.capacity_gte" placeholder="Guests" clearable @change="capacityFilter"
                style="margin-left: 10px; min-width: 90px">
                <el-option v-for="item in 99" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </div>
            <el-date-picker class="distance-top-margin" style="width: 100%" v-model="data.last_update"
              type="date" placeholder="Last Update" format="MM/dd/yyyy"
              value-format="yyyy-MM-dd" @change="lastUpdateFilter">
            </el-date-picker>
          </div>
          <div class="setting-width" style="margin: 0 10px 0 10px">
            <div class="row">
              <el-select v-model="data.bedroom_num_gte" placeholder="Beds" @change="bedroomFilter" clearable>
                <template slot="prefix">
                  <img src="../../../assets/icon/bed.svg" class="prefix_icon" />
                </template>
                <el-option v-for="item in 99" :key="item" :label="item" :value="item">

                </el-option>
              </el-select>
              <el-input style="margin-left: 10px;" v-model="data.key_number" placeholder="Key #"
                @change="keyNumberFilter" @keyup.enter.native="keyNumberFilter">
                <template #prefix>
                  <img src="../../../assets/icon/key.svg" class="prefix_icon" />
                </template>
              </el-input>
            </div>
            <div>
              <div class="select-state">
                <el-checkbox @change="showWithoutPrice" v-model="data.show_without_price">Include listings without rates
                </el-checkbox>
              </div>
              <div class="select-state" style="margin-top: 3px;">
                <el-checkbox @change="activateChange" v-model="data.show_inactive">Include inactive listings
                </el-checkbox>
              </div>
            </div>
          </div>
          <div class="setting-width" style="margin: 0 10px 0 10px; flex-direction: column; display: flex; gap: 10px;">
            <el-button class="clear-button" @click="reset">Clear Filter</el-button>
            <el-button class="new-list-button" style="margin-left: 0px;" @click="addProperty">+ Add a Listing</el-button>
          </div>
        </div>
      </div>
      <div class="properties-top-row row">
        <el-button class="primary-button move" @click="addProperty">+ New Listing</el-button>
        <div class="move">
          <div class="move-btn-row">
            <el-button style="padding: 2px 5px" v-if="popupVisible === false" class="reference"
              @click="popupVisible = true">
              <img src="../../../assets/icon/ico-filter.svg" alt="" />
            </el-button>
            <el-button style="padding: 2px 5px" v-else class="reference" @click="popupVisible = false">
              <img src="../../../assets/icon/ico-filter.svg" alt="" />
            </el-button>
            <mt-popup :modal="false" v-model="popupVisible" class="mt-popup" position="bottom">
              <div class="mt-popup-contact">
                <Search-Filter ref="child" @searchFilterMove="searchFilterMove" @searchFilter="searchFilter"
                  @clear="clear" :mouthDate="mouthdate.date"></Search-Filter>
              </div>
            </mt-popup>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import SearchFilter from "./SearchFilter";
import dayjs from 'dayjs'

export default {
  components: { SearchFilter },
  data() {
    return {
      mouthdate: { date: "" },
      checkAll: false,
      isIndeterminate: false,
      showPrice: false,
      showAmenities: false,
      popoverShow: false,
      priceValue: [0, 30000],
      data: {
        dates: [],
        last_update: '',
        area: [],
        key_number: "",
        owner_last_name: "",
        nights: "",
        capacity_gte: "",
        min_price: null,
        max_price: null,
        address: "",
        show_inactive: false,
        show_without_price: false,
        bedroom_num_gte: null,
        air_conditioning_centeral: false,
        air_conditioning_minisplit: false,
        air_conditioning_windowunits: false,
        pets_askowner: false,
        pets_yes: false,
        pets_no: false,
        pool_private: false,
        pool_community: false,
        walk_to_beach: false,
        waterfront: false,
        water_views: false,
      },
      popupVisible: false,
    };
  },
  computed: mapState({
    areas: (state) => state.areas,
  }),
  methods: {
    ...mapActions([
      "getAreas",
    ]),
    priceChange(val) {
      this.priceValue = val
      this.data.min_price = this.priceValue[0]
      this.data.max_price = this.priceValue[1]
    },

    handleCheckAllChange(val) {
      let arr = this.areas.map((item) => {
        return item.id;
      });
      this.data.area = val ? arr : [];
      this.isIndeterminate = false;
      this.$emit("areaFilter", this.data.area);
    },
    activateChange() {
      this.$emit("activateChange", this.data.show_inactive);
    },
    showWithoutPrice() {
      this.$emit("showWithoutPrice", this.data.show_without_price);
    },
    addressFilter() {
      this.$emit("addressFilter", this.data.address);
    },
    keyNumberFilter() {
      this.$emit("keyNumberFilter", this.data.key_number);
    },
    ownerFilter() {
      this.$emit("ownerFilter", this.data.owner_last_name);
    },
    areaFilter(value) {
      this.$emit("areaFilter", this.data.area);
      let checkedCount = value?.length;
      this.checkAll = checkedCount === this.areas.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.areas.length;
    },
    confirmPrice() {
      this.$emit("priceChange", this.data.min_price, this.data.max_price);
      this.showPrice = false;
    },
    searchFilter(val) {
      this.$emit("searchFilter", val);
    },
    searchFilterMove(val) {
      this.$emit("searchFilterMove", val);
    },
    // 新增房屋
    addProperty() {
      this.$store.commit("setPropertyInfor", false);
      this.$store.commit("setPropertyCalendar", false);
      this.$store.commit("setPropertyPhoto", false);
      this.$store.commit("setPropertyReuirement", false);
      this.$store.commit("setPropertySevice", false);
      this.$router.push({ name: "create-property" });
    },
    dateFilter(date) {
      this.$emit("dateFilter", this.data.dates);
    },
    lastUpdateFilter(date) {
      this.$emit("lastUpdateFilter", this.data.last_update);
    },
    capacityFilter(val) {
      this.$emit("capacityFilter", val);
    },
    consecutiveFilter(val) {
      this.$emit("consecutiveFilter", val);
    },
    bedroomFilter(val) {
      this.$emit("bedroomFilter", val);
    },
    amenitiesFilter() {
      this.showAmenities = false;
      let ac_type = []
      let pet_allow = []
      let pool_type = []
      if(this.data.air_conditioning_centeral) ac_type.push("1")
      if(this.data.air_conditioning_minisplit) ac_type.push("2")
      if(this.data.air_conditioning_windowunits) ac_type.push("3")
      if(this.data.pets_yes) pet_allow.push("true")
      if(this.data.pets_no) pet_allow.push("false")
      if(this.data.pets_askowner) pet_allow.push("ask_owner")
      if(this.data.pool_private) pool_type.push("2")
      if(this.data.pool_community) pool_type.push("1")
      
      this.$emit("amenitiesFilter", {ac_type, pet_allow, pool_type, water_views: this.data.water_views, waterfront:this.data.waterfront, walk_to_beach: this.data.walk_to_beach})
    },
    // 重置过滤条件
    reset() {
      this.data = {
        address: "",
        dates: [],
        last_dates: [],
        area: [],
        min_price: null,
        max_price: null,
        key_number: "",
        owner_last_name: "",
        nights: null,
        capacity_gte: null,
        show_inactive: false,
        air_conditioning_centeral: false,
        air_conditioning_minisplit: false,
        air_conditioning_windowunits: false,
        pets_askowner: false,
        pets_yes: false,
        pets_no: false,
        pool_private: false,
        pool_community: false,
        walk_to_beach: false,
        waterfront: false,
        water_views: false,
      };
      this.$store.commit("setPropertiesFilter", null);
      this.$refs.child.clearInfor();
      this.$emit("reset");
    },
    clear() {
      // this.$refs.child.clearInfor();
      this.popoverShow = false;
      this.popupVisible = false;
    },
  },
  mounted() {
    let obj = {
      dates: [
        this.$route.query.start_date ? this.$route.query.start_date : "",
        this.$route.query.end_date ? this.$route.query.end_date : "",
      ],
      last_dates: [
        this.$route.query.calendar_start_date ? this.$route.query.calendar_start_date : "",
        this.$route.query.calendar_end_date ? this.$route.query.calendar_end_date : "",
      ],
      area: [],
    };
    if (
      this.$route.query.area &&
      this.$route.query.area.constructor === Array
    ) {
      obj.area = this.$route.query.area.map(Number);
    } else if (this.$route.query.area) {
      obj.area.push(Number(this.$route.query.area));
    }
    if (this.$route.query.show_inactive != "true") {
      this.$route.query.show_inactive = false;
    } else {
      this.$route.query.show_inactive = true;
    }
    setTimeout(() => {
      this.data = Object.assign({}, this.$route.query, obj);
      // if(!this.data.last_update) {
      //   this.data.last_update = dayjs().format('YYYY-MM-DD');
      // }
    }, 1000);
    setTimeout(() => {
      if (this.data.area.length === this.areas.length) {
        this.checkAll = true;
      }
      if (this.data.area.length > 0) {
        this.isIndeterminate = true;
      }
    }, 2000);
  },
  created() {
    let myDate = new Date();
    let tYear = myDate.getFullYear();
    let tMonth = myDate.getMonth() + 1;
    let date = "";
    if (tMonth > 9) {
      date = `${tYear + 1}/5/01`;
    }else if(tMonth <= 5){
      date = `${tYear}/5/01`;
    } else {
      date = `${tYear}/${tMonth}/01`;
    }
    this.mouthdate.date = date;
    if (this.areas.length === 0) {
      this.getAreas();
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/button.scss";

.popper {
  .price-row {
    justify-content: space-between;
  }

  .slider-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &.row{
      margin:0px;
    }

    .price-slider {
      width: 200px;
      padding: 0 20px;
    }
  }

  .form-item {
    width: calc(calc(100% - 10px) / 2);

    .form-item-title {
      line-height: 24px;
      margin: 0;
    }

    /deep/ .el-input {
      /*在firefox下移除input[number]的上下箭头*/
      .el-input__inner {
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none !important;
        }
      }

      .el-input__prefix {
        line-height: 40px;
      }

    }
  }
}

/deep/ .el-input--prefix .el-input__inner {
  padding-left: 40px !important;
}

.prefix_icon {
  height: 100%;
  width: 19px;
  text-align: center;
  line-height: 40px;
  margin-left: 10px;
}

.top_search {
  margin: 0px 0 30px 0;

  .title_container {
    display: flex;
    flex-direction: row;
    gap: 99px;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 5px;

    .title {
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      color: #092A35;
      gap: 15px;
      margin-top: 0px;
    }
  }

  .properties-top-row {
    justify-content: space-between;
    align-items: flex-start;


    .properties-filter {
      width: 100%;

      .properties-filter-infor {
        align-items: flex-start;
        flex-wrap: nowrap;

        /deep/ .el-range-editor .el-range-input {
          text-align: left;
        }

        .setting-width {
          width: 30%;
          min-width: 220px;

          /deep/ .row{
            flex-wrap: nowrap;
          }

          .select-state {
            margin-top: 10px;
            text-align: left;
          }
        }

        .el-button {
          width: 100%;
        }

        .clear-button {
          background-color: white;
          border: solid 1px #F4B624;
          color: #F4B624;
          height: 42px;
        }

        .new-list-button {
          color: #092A35;
          border: solid 1px #092A35;
          font-size: 15px;
          font-weight: 700;
          line-height: 20.49px;
          height: 42px;
        }
      }

      .distance-top-margin {
        margin-top: 10px;
      }

      .reference {
        width: 100%;
        // margin: 0 10px 0 0px;
        height: 40px;
        padding: 0;
        color: #606266;

        .btn-row {
          width: calc(100% - 20px);
          margin: 0;
          padding: 0 10px;
          justify-content: flex-start;
          gap: 15px;
        }

        &:hover {
          background-color: #fff;
          border-color: #dcdfe6;
          color: #606266;
        }
      }

      .show-price {
        background-color: #fff;
        line-height: 40px;
        font-size: 14px;
      }
    }
  }

  .move {
    display: none;
  }

  .move-btn-row {
    text-align: right;
  }

  .mt-popup {
    width: 100%;
    height: calc(100% - 300px);
    box-shadow: 0px -10px 20px rgba(17, 61, 71, 0.05);
    overflow: scroll;
    z-index: 101 !important;
    text-align: left;

    .mt-popup-contact {
      padding: 20px;
    }
  }

  @media (max-width: 992px) {
    .move {
      display: inline-block;
    }

    .move.primary-button {
      margin-right: 20px;
    }

    .move-btn-row .el-button {
      height: 40px;
      width: 80px;
    }
    .new-list-button {
      display: none;
    }
    .properties-top-row {
      .properties-filter {
        display: none;
      }
    }
  }
}
</style>