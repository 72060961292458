<template>
  <div class="search-box">
    <div class="row contact-row">
      <div class="contact-row-item move">
        <div class="row contact-row">
          <div class="contact-row-item setting-padding move-row-item">
            <p class="contact-title">Date Range Min</p>
            <el-date-picker style="width: 100%" :picker-options="pickerOptions0"   v-model="data.start_date"  :default-value="mouthdate.start_date" type="date" format="MM/dd/yyyy" value-format="yyyy-MM-dd" >
            </el-date-picker>
          </div>
          <div class="contact-row-item setting-padding move-row-item">
            <p class="contact-title">Date Range Max</p>
            <el-date-picker style="width: 100%" :picker-options="pickerOptions1" v-model="data.end_date"  :default-value="mouthdate.end_date" type="date" format="MM/dd/yyyy" value-format="yyyy-MM-dd" >
           </el-date-picker>
          </div>
        </div>
      </div>
      <div class="contact-row-item move">
        <p class="contact-title">Nights</p>
        <el-select v-model="data.nights_count" placeholder=""  clearable  >
            <el-option v-for="item in 99" :key="item" :label="item" :value="item" ></el-option>
        </el-select>
      </div>
      <div class="contact-row-item move">
        <div class="row contact-row">
          <div class="contact-row-item setting-padding move-row-item">
            <p class="contact-title">Price Min</p>
            <el-input type="number" v-model="data.min_price">
              <strong slot="prefix">$</strong>
            </el-input>
          </div>
          <div class="contact-row-item setting-padding move-row-item">
            <p class="contact-title">Price Max</p>
            <el-input type="number" v-model="data.max_price">
                <strong slot="prefix">$</strong>
            </el-input>
          </div>
        </div>
      </div>
      <div class="contact-row-item move" >
        <p class="contact-title">Guests</p>
         <el-select v-model="data.capacity_gte" placeholder="" clearable>
            <el-option v-for="item in 99" :key="item" :label="item" :value="item" ></el-option>
          </el-select>
      </div>
      <div class="contact-row-item move" >
        <p class="contact-title">Neighborhood</p>
        <el-select v-model="data.area" multiple placeholder="" collapse-tags class="distance-top-margin" style="width: 100%" >
            <el-checkbox style="margin: 10px 20px" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">Select All</el-checkbox>
            <el-option v-for="item in areas" :key="item.id" :label="item.name" :value="item.id" ></el-option>
        </el-select>
      </div>
       <div class="contact-row-item move" >
        <p class="contact-title">Street address</p>
        <el-input class="distance-top-margin" v-model="data.address" placeholder=""/>
      </div>
       <div class="contact-row-item move" >
        <p class="contact-title">Key #</p>
        <el-input class="distance-top-margin" v-model="data.key_number" placeholder=""/>
      </div>
       <div class="contact-row-item move" >
        <p class="contact-title">Owner</p>
        <el-input class="distance-top-margin" v-model="data.owner_last_name"  @keyup.enter.native="ownerFilter" placeholder="" />
      </div>
      <div class="contact-row-item">
        <p class="contact-title">Turnover Day</p>
        <el-select v-model="data.turnover_day" multiple placeholder>
          <el-option v-for="item in week" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </div>
      <div class="contact-row-item">
        <div class="row contact-row">
          <div class="contact-row-item move-row-item">
            <p class="contact-title">Bedrooms Min</p>
            <el-select v-model="data.bedroom_num_gte" clearable  placeholder>
              <el-option v-for="item in 99" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </div>
          <div class="contact-row-item move-row-item">
            <p class="contact-title">Bedrooms Max</p>
            <el-select v-model="data.bedroom_num_lte" clearable  placeholder>
              <el-option v-for="item in 99" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="contact-row-item">
        <div class="row contact-row">
          <div class="contact-row-item move-row-item">
            <p class="contact-title">Bathrooms Min</p>
            <el-select v-model="data.bathroom_num_gte" clearable  placeholder>
              <el-option v-for="item in 99" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </div>
          <div class="contact-row-item move-row-item">
            <p class="contact-title">Bathrooms Max</p>
            <el-select v-model="data.bathroom_num_lte" clearable  placeholder>
              <el-option v-for="item in 99" :key="item" :label="item" :value="item" ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="contact-row-item">
        <p class="contact-title">A/C Type</p>
        <el-select v-model="data.ac_type" clearable  placeholder>
          <el-option label="Any" :value="0"></el-option>
          <el-option v-for="item in acTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="contact-row-item">
        <p class="contact-title">Pool Type</p>
        <el-select v-model="data.pool_type" clearable  placeholder>
          <el-option label="Any" :value="0"></el-option>
          <el-option v-for="item in poolTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="contact-row-item">
        <p class="contact-title">Pets</p>
        <el-select v-model="data.pet_allow" clearable  placeholder>
          <el-option label="Yes" :value="true"></el-option>
          <el-option label="No" :value="false"></el-option>
          <el-option label="Ask Owner" :value="'ask_owner'"></el-option>
        </el-select>
      </div>
      <div class="contact-row-item">
        <p class="contact-title">Listing Priority</p>
         <el-select v-model="data.priority" clearable placeholder="">
            <el-option v-for="item in propertyPriorityList" :key="item.id" :label="item.name" :value="item.id" ></el-option>
          </el-select>
      </div>
      <div class="select-state">
        <el-checkbox v-model="data.hot_tub_or_spa">Hot Tub</el-checkbox>
        <el-checkbox v-model="data.waterfront">Waterfront</el-checkbox>
        <el-checkbox v-model="data.water_view">Water View</el-checkbox>
        <el-checkbox v-model="data.show_without_price">Include listings without rates</el-checkbox>
        <el-checkbox class="move" v-model="data.show_inactive">Include inactive listings</el-checkbox>
      </div>
    </div>
    <div class="btn">
      <el-button class="light-blue-button" @click="clear">Cancel</el-button>
      <el-button class="block-button counter-move" @click="searchFilter">Apply</el-button>
      <el-button class="block-button move-btn"  @click="searchFilterMove">Apply</el-button>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import dayjs from "dayjs"
export default {
  props:["mouthDate"],
  data() {
    return {
       pickerOptions0: {
          disabledDate: (time) => {
            let arrivalDateVal = this.data.end_date;
            if (arrivalDateVal) {
              this.mouthdate.start_date = dayjs(arrivalDateVal).format("YYYY/MM")+"/01"
              return time.getTime() > new Date(arrivalDateVal).getTime();
            }
          },
        },
        pickerOptions1: {
          disabledDate: (time) => {
            let arrivalDateVal = this.data.start_date;
            if (arrivalDateVal) {
              this.mouthdate.end_date = dayjs(arrivalDateVal).format("YYYY/MM")+"/01"
              return time.getTime() < new Date(arrivalDateVal).getTime();
            }
          },
        },
      isIndeterminate: false,
      checkAll: false,
      mouthdate: {start_date:this.mouthDate,end_date:this.mouthDate},
      data: {
        hot_tub_or_spa:false,
        show_inactive: false,
        waterfront: false,
        water_view: false,
        show_without_price: false, // 显示未设置价格的房子
        bedroom_num_gte: "",
        bedroom_num_lte: "",
        bathroom_num_gte: "",
        bathroom_num_lte: "",
        pool_type: null,
        turnover_day: "",
        owner_name: "",
        address: "",
        pet_allow: null,
        ac_type: null,
        priority:"",
        area: [],
        key_number: "",
        owner_last_name: "",
        nights_count: "",
        capacity_gte: "",
        min_price: null,
        max_price: null,
        start_date:"",
        end_date:"",
      },
      week: [ "Any", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday",  "Saturday", "Sunday", ],
    };
  },
  computed: mapState({
    areas: (state) => state.areas,
    propertyPriorityList: (state) => state.enum.propertyPriorityList,
    acTypeList: (state) => state.enum.acTypeList,
    poolTypeList: (state) => state.enum.poolTypeList,
  }),
  methods: {
    ...mapActions(["getAreas", "getPropertyPriorityList", "getACTypeList", "getPoolTypeList"]),
    handleCheckAllChange(val) {
      let arr =this.areas.map((item)=>{return item.id})
        this.data.area = val ? arr : [];
        this.isIndeterminate = false;
        // this.$emit("areaFilter", this.data.area);
      },
    searchFilter() {
      let obj = Object.assign({},this.data);
      delete obj.area
      delete obj.key_number
      delete obj.owner_last_name
      delete obj.nights_count
      delete obj.capacity_gte
      delete obj.min_price
      delete obj.max_price
      delete obj.address
      delete obj.show_inactive
      delete obj.start_date
      delete obj.end_date
       this.$emit("searchFilter",  obj);
    },
    searchFilterMove(){
      this.$emit("searchFilterMove", this.data);
    },
    clear() {
      this.$emit("clear");
    },
    clearInfor() {
      this.data.inactive = this.data.show_without_price = this.data.waterfront = this.data.water_view = this.data.hot_tub_or_spa = false;
      this.data.bedroom_num_gte = this.data.bedroom_num_lte = this.data.bathroom_num_gte = this.data.bathroom_num_lte = null;
      this.data.turnover_day = this.data.owner_name = this.data.address = this.data.area = this.data.pet_allow =
        "";
      this.data.pool_type = this.data.ac_type = null;
    },
  },
  created() {
    if (this.propertyPriorityList.length === 0) {
      this.getPropertyPriorityList();
    }
    if (this.areas.length === 0) {
      this.getAreas();
    }
    if (this.acTypeList.length === 0) {
      this.getACTypeList();
    }
    if (this.poolTypeList.length === 0) {
      this.getPoolTypeList();
    }
      
  },
   mounted() {
      let obj = {
        start_date: this.$route.query.start_date ? this.$route.query.start_date : "",
        end_date: this.$route.query.end_date ? this.$route.query.end_date : "",
        area:[]
      };
      if(this.$route.query.hot_tub_or_spa!="true"){
         this.$route.query.hot_tub_or_spa = false
      }else{
        this.$route.query.hot_tub_or_spa = true;
      }
      if(this.$route.query.waterfront!="true"){
         this.$route.query.waterfront = false
      }else{
        this.$route.query.waterfront = true;
      }
      if(this.$route.query.water_view!="true"){
         this.$route.query.water_view = false
      }else{
        this.$route.query.water_view = true;
      }
      if(this.$route.query.show_without_price=="true"){
         this.$route.query.show_without_price = true
      }else  if(this.$route.query.show_without_price =="false"){
        this.$route.query.show_without_price = false;
      }

      if(this.$route.query.pet_allow=="true"){
         this.$route.query.pet_allow = true
      } else if(this.$route.query.pet_allow=="false"){
        this.$route.query.pet_allow = false;
      }
      if(this.$route.query.pool_type){
        this.$route.query.pool_type = Number(this.$route.query.pool_type)
      }
      if(this.$route.query.ac_type){
        this.$route.query.ac_type = Number(this.$route.query.ac_type)
      }
      if(this.$route.query.area&&this.$route.query.area.constructor === Array){
        obj.area = this.$route.query.area.map(Number)
      }else if(this.$route.query.area){
       obj.area.push(Number(this.$route.query.area))
      }
      if(this.$route.query.show_inactive!="true"){
         this.$route.query.show_inactive = false
      }else{
        this.$route.query.show_inactive = true;
      }
      this.data = Object.assign({},this.$route.query,obj);
      setTimeout(() => {
        if( this.data.area.length === this.areas.length ){
          this.checkAll = true
        }if(this.data.area.length > 0){
          this.isIndeterminate = true;
        }
      }, 4000);
      
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/button.scss";
.search-box {
  .contact-row {
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    .contact-row-item {
      width: calc(calc(100% - 20px) / 2);
      .contact-title {
        color: #38425b;
        font-size: 14px;
        margin: 10px 0 5px 0;
        font-family: "Roboto-Regular", sans-serif;
      }
      /deep/ .el-select,
      /deep/ .el-input {
        width: 100%;
      }
    }
     .setting-padding /deep/ .el-input {
        .el-input__inner {
          padding-left: 45px;
          -moz-appearance: textfield;
          &::-webkit-inner-spin-button,&::-webkit-outer-spin-button{
            -webkit-appearance: none !important;
          }
        }
        .el-input__prefix {
          left: 1px;
          width: 35px;
          top: 1.5px;
          height: calc(100% - 3px);
          line-height: 40px;
          background-color: #f4f9fc;
        }
      }
    .select-state {
      margin: 10px 0;
    }
  }
  .btn {
    padding: 10px;
    position: sticky;
    bottom: 0;
    left: 0;
    background-color: white;
    z-index: 10;
    .el-button {
      width: 100px;
    }
  }
  .move,.move-btn{display: none;}
  .counter-move {display: inline-block;}
  @media (max-width:992px) {
    .move{display: block;}
    .move-btn{display: inline-block;}
    .counter-move {display: none;}
    .pc{display: none;}
  }
  .btn{text-align: center;}
  @media (max-width: 500px) {
     .contact-row .contact-row-item{ width: 100%; .move-row-item{width: calc(calc(100% - 20px) / 2);}}
  }
}
</style>