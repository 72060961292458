<template>
  <div class="properties-box">
    <div class="table">
      <Listing-Table 
        @sort="sort" 
        @checked="checked"
        @showAllPage="showAllPage" 
        @load="load" 
        :flag="flag" 
        :data="data" 
        :searchInfor="searchInfor" 
        :header="tableHeader" 
        :option="tableOption" 
        :offset="offset" 
        :total="total" 
        :limit="limit" 
        ref="listingData">
      </Listing-Table>
    </div>
    <mt-popup :modal="false" v-model="popupVisible" class="mt-popup" position="bottom">
        <el-radio-group class="row move-row">
        <div class="subset">
          <el-radio-button :label="1" @click.native.prevent="subset" >Subset</el-radio-button>
        </div>
        <div class="subset">
          <el-radio-button :label="2" @click.native.prevent="shareListings">Share Listings</el-radio-button>
        </div>
        <div class="subset">
          <el-radio-button :label="3" @click.native.prevent="showPDF = true">Download Listing Sheets</el-radio-button>
        </div>
        <div class="subset">
          <el-radio-button :label="4" @click.native.prevent="handleEmailBtnClick">Email Owners</el-radio-button>
        </div>
      </el-radio-group>
    </mt-popup>
    <div class="footer-download">
      <div style="text-align: center">
        <el-radio-group class="row">
          <div class="subset">
            <el-radio-button :label="1" @click.native.prevent="subset" >Subset</el-radio-button>
          </div>
          <div class="subset">
            <el-radio-button :label="2" @click.native.prevent="shareListings">Share Listings</el-radio-button>
          </div>
          <div class="sheet_owner">
            <el-radio-button :label="3" @click.native.prevent="showPDF = true">Download Listing Sheets</el-radio-button>
          </div>
          <div class="sheet_owner">
            <el-radio-button :label="4" @click.native.prevent="handleEmailBtnClick">Email Owners</el-radio-button>
          </div>
        </el-radio-group>
      </div>
    </div>
    <!-- 下载pdf -->
    <el-dialog  class="pdf" :visible.sync="showPDF" title="Choose to Download">
      <div class="pdf-contect-select">
        <el-radio-group v-model="select_download_type">
          <p class="select-item">
            <el-radio :label="1">Listing Sheet with Photos</el-radio>
          </p>
          <p class="select-item">
            <el-radio :label="2">Listing Sheet Only</el-radio>
          </p>
          <p class="select-item">
            <el-radio :label="3">Listing Photos Only</el-radio>
          </p>
        </el-radio-group>
      </div>
      <div class="pdf-btn">
        <el-button class="primary-button" :loading="loadingBtn" @click="downloadListingsPDF">Download</el-button>
      </div>
    </el-dialog>
    <!-- share listing的弹出框 -->
    <Share-Listings-Dialog ref="shareListingsDialog" :listings="checkData" :searchInfor="searchInfor" ></Share-Listings-Dialog>
    <!-- owner 给房屋的主人发送email -->
    <el-dialog :visible.sync="showEmailPopup"  title="Email Owners">
      <div class="view">
        <p class="view_tip">Body</p>
        <el-input type="textarea" resize="none" :rows="10" v-model="body" ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="send" @click="sendEmailToOwner" :loading="fullscreenLoading" >Send</el-button >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ShareListingsDialog from "./ShareListingsDialog";
import Page from "../../common/Paging";
import { downloadFile } from "../../../API/utils";
import {  apiDownloadListingPDF, apiDownloadListingPhotoPDF, apiEmailOwners } from "../../../API/api";
import ListingTable from "./ListingTable";
export default {
  props: ["data", "total", "currentPage", "searchInfor", "offset", "limit", "flag"],
  components: { ShareListingsDialog, Page, ListingTable },
  data() {
    return {
      popupVisible: false,
      loadingBtn: false,
      select_download_type: 1,
      showPDF: false,
      checkData: [],
      fullscreenLoading: false,
      // Email Owners 对话框的数据
      showEmailPopup: false,
      body: "",
      loaidngPDF: false,
      tableOption: {
        border: false,
      },
      tableHeader: [
        {
          prop: "peak_rate",
          label: "PRICE on SEARCH CRITERIA",
          width: "250px",
          sortable: "custom",
        },

        {
          label: "IMAGE",
          width: "120px",
        },
        {
          prop: "address",
          label: "ADDRESS",
          width: "160px",
          sortable: "custom",
        },
        {
          prop: "area__name",
          label: "AREA",
          minWidth: "120px",
          sortable: "custom",
        },
        {
          prop: "bedroom_number",
          label: "BRS",
          minWidth: "70px",
          sortable: "custom",
        },
        {
          prop: "bathroom_number",
          label: "BATHS",
          minWidth: "90px",
          sortable: "custom",
        },
        {
          prop: "capacity",
          label: "CAPACITY",
          minWidth: "110px",
          sortable: "custom",
        },
        {
          prop: "homeowner__last_name",
          label: "OWNER",
          minWidth: "120px",
          sortable: "custom",
        },
        {
          prop: "requirement__turnover_day",
          label: "TURNOVER",
          minWidth: "120px",
          sortable: "custom",
        },
        {
          prop: "calendar_updated_at",
          label: "LAST UPDATE",
          minWidth: "140px",
          sortable: "custom",
        },
        {
          prop: "key_number",
          label: "KEY",
          minWidth: "90px",
          sortable: "custom",
        },
        {
          // prop: "",
          label: "LATEST LEASED",
          minWidth: "160px",
          // sortable: "",
        },
      ],
    };
  },
  methods: {
    load(){
      this.$emit("load")
    },
    closeFlag(){
      this.$refs.listingData.flags = false;
    },
    showAllPage(){
      this.$emit("showAllPage")
    },
    pagesChanged(number) {
      this.$refs.listingData.topClick();
      this.$emit("pagesChanged", number);
    },
    handleEmailBtnClick() {
      this.showEmailPopup = true;
    },
    // 创建租约
    createLease(listing) {
      this.$store.commit("setLeaseParam", { listing: listing.listing_id,  listingAddress: listing.address, });
      this.$router.push({ name: "create-lease" });
    },
    sort(val) {
      this.$emit("sort", val);
    },
    // 房子勾选
    checked(item) {
      if(item.length!=0) this.popupVisible = true;
      else this.popupVisible = false
      this.checkData = item;
      this.$store.commit("setPropertiesChecked", item);
    },
    // 移除未勾选的房子
    subset() {
      this.$emit("subset", this.checkData);
    },
    //  下载pdf
    downloadListingsPDF() {
      if (this.checkData.length === 0) return;
      this.loadingBtn = true;
      if (this.select_download_type === 1) {
        this.listingsPDF(this.checkData);
        this.listingsPhotosPDF(this.checkData);
      } else if (this.select_download_type === 2) {
        this.listingsPDF(this.checkData);
      } else if (this.select_download_type === 3) {
        this.listingsPhotosPDF(this.checkData);
      }
    },
    listingsPDF(Listings) {
      apiDownloadListingPDF({listings: Listings.map((listing) => { return listing.listing_id;}),}).then((res) => {
          downloadFile( res.data, res.headers["content-disposition"].split("=")[1],  "application/pdf" );
          this.$message.success("Download listings pdf successful");
        }).catch((err) => {
          this.$message.error( "Download listings pdf fail, please try again later" );
        }).finally(() => {
          this.showPDF = false;
          this.loadingBtn = false;
        });
    },
    listingsPhotosPDF(Listings) {
      Listings.map((listing) => {
        apiDownloadListingPhotoPDF({
          listing: listing.listing_id,
        }).then((res) => {
            downloadFile(res.data,res.headers["content-disposition"].split("=")[1],"application/pdf");
            this.$message.success("Download listings pdf successful");
          }).catch((err) => {
            this.$message.error( "Download listings pdf fail, please try again later" );
          }).finally(() => {
            this.showPDF = false;
            this.loadingBtn = false;
          });
      });
    },
    // 分享房屋给 Contact
    shareListings() {
      this.$refs.shareListingsDialog.show = true;
    },
    // 发送邮件给 Owner
    async sendEmailToOwner() {
      console.log('click');
      if (this.$store.state.propertiesCheckeds.length === 0) {
        this.$message.error("No properties selected");
        return;
      }
      if (this.body.trim() === "") {
        this.$message.error("Body can not be empty");
        return;
      }
      this.fullscreenLoading = true;
      
      console.log(this.$store.state.propertiesCheckeds, this.body);

      apiEmailOwners({
        listings: this.$store.state.propertiesCheckeds.map((listing) => listing.listing_id),
        email_body: this.body.trim(),
      }).then((res) => {
        this.$message.success("Successfully sent Emails to the selected owners!");
        this.showEmailPopup = false;
        this.body = "";
        this.fullscreenLoading = false;
      }).catch((err) => {
        this.$message.error(err.response.data.detail);
        this.fullscreenLoading = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/table.scss";
@import "../../../css/button.scss";
@import "../../../css/dialog.scss";
@import "../../../css/footerdownload.scss";
.properties-box {
  .table {
    // margin-bottom: 100px;
    /deep/ .el-table td {
    padding: 10px 0;
  }}
  .pageing {
    background-color: #fff;
    padding-top: 20px;
    margin-top: 0;
    padding-bottom: 150px;
  }
  .view {
    text-align: left;
    .view_tip {
      font-family: "Roboto-Regular", sans-serif;
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
  // pdf
  .pdf-contect-select {
    margin-top: 10px;
    .select-item {
      text-align: left;
      margin-top: 7px;
      /deep/ .el-radio__input.is-checked .el-radio__inner {
        border: none;
        background: none;
        background-image: url("../../../assets/img/true.png");
        background-size: cover;
        &::after {
          display: none;
        }
      }
      /deep/ .el-radio__label {
        font-size: 16px;
      }
      /deep/ .el-radio__input.is-checked + .el-radio__label {
        color: #606266;
      }
    }
  }
  .pdf-btn {
    text-align: right;
    margin-top: 30px;
  }
  .mt-popup{
    width: 100%;
    display: none;height: 150px;overflow: scroll;
    padding-top: 20px;
    z-index: 10;
    .move-row{-webkit-flex-wrap: wrap;flex-wrap: wrap;}
    .subset{width: calc(calc(100% - 80px) / 2);min-width: 200px; padding: 0 20px; margin:10px 0 0 0;
    /deep/ .el-radio-button{width: 100%;
      .el-radio-button__inner{color: #678993;border: 1px solid #678993; box-sizing: border-box; border-radius: 4px;width: 100%;}}
    }
  }
  /deep/ .el-dialog{width: 600px;}
  .pdf /deep/.el-dialog{width: 400px;}
  @media (max-width:992px) {
    /deep/ .el-dialog,.pdf /deep/.el-dialog{width: 90%;}
   .mt-popup{display: block ;}

  }
}
</style>
