<template>
  <div class="properties-box">
    <div>
      <Properties-Search
        ref="topSearch"
        @capacityFilter="capacityFilter"
        @consecutiveFilter="consecutiveFilter"
        @bedroomFilter="bedroomFilter"
        @dateFilter="dateFilter"
        @lastUpdateFilter="lastUpdateFilter"
        @searchFilter="searchFilter"
        @priceChange="priceChange"
        @areaFilter="areaFilter"
        @addressFilter="addressFilter"
        @keyNumberFilter="keyNumberFilter"
        @ownerFilter="ownerFilter"
        @activateChange="activateChange"
        @showWithoutPrice="showWithoutPrice"
        @reset="reset"
        @searchFilterMove="searchFilterMove"
        @amenitiesFilter = "amenitiesFilter"
      ></Properties-Search>
      <div class="properties-contect" ref="viewbox"  v-loading.fullscreen.lock="loading">
        <Properties-Table
          ref="properFlag"
          :data="data"
          :total="total"
          :flag="flag"
          :offset="offset"
          :currentPage="currentPage"
          :searchInfor="searchInfor"
          :limit="limit"
          @pagesChanged="pagesChanged"
          @sort="sort"
          @subset="subset"
          @showAllPage="showAllPage"
          @load="load"
        ></Properties-Table>
      </div>
    </div>
  </div>
</template>
<script>
import { apiGetListings } from "../../API/api";
import ShareListingsDialog from "./Component/ShareListingsDialog";
import PropertiesSearch from "./Component/PropertiesSearch";
import PropertiesTable from "./Component/PropertiesTable";
import { validateObjectNull } from '../../js/null';
export default {
  data() {
    return {
      count: 5,
      checkeds: [],
      reload: false,
      // Email Owners 对话框的数据
      show: false,
      body: "",
      fullscreenLoading: false,
      loading: false,
      img: require("../../assets/img/20191220101919.png"),
      searchInfor: {},
      search: "",
      ordering: "not_renting_year,priority,-calendar_updated_at", // 排序
      total: 1, // 总页数
      currentPage: 0, // 当前页数
      limit: 25, // 每页显示数量
      checkData: [], // 勾选的房子
      data: [], //当前显示的数据
      nomore: false,
      flag: false,
      loaded: true,
    };
  },
  computed: {
    offset() {
      return (this.currentPage - 1) * this.limit;
    },
    query() {
      // 查询参数
      return {
        limit: this.limit,
        offset: this.offset,
        start_date: this.searchInfor.start_date,
        end_date: this.searchInfor.end_date,
        last_update: this.searchInfor.last_update,
        calendar_start_date: this.searchInfor.calendar_start_date,
        calendar_end_date: this.searchInfor.calendar_end_date,
        nights_count: this.searchInfor.nights_count,
        min_price: this.searchInfor.min_price,
        max_price: this.searchInfor.max_price,
        capacity_gte: this.searchInfor.capacity_gte,
        bathroom_num_lte: this.searchInfor.bathroom_num_lte,
        bathroom_num_gte: this.searchInfor.bathroom_num_gte,
        bedroom_num_lte: this.searchInfor.bedroom_num_lte,
        bedroom_num_gte: this.searchInfor.bedroom_num_gte,
        ac_type: this.searchInfor.ac_type,
        pool_type: this.searchInfor.pool_type,
        pet_allow: this.searchInfor.pet_allow,
        turnover_day: this.searchInfor.turnover_day,
        area: this.searchInfor.area,
        show_without_price: this.searchInfor.show_without_price,
        show_inactive: this.searchInfor.show_inactive,
        ordering: this.ordering,
        show_rates: true,
        show_price:true,
        address: this.searchInfor.address,
        owner_last_name: this.searchInfor.owner_last_name,
        key_number: this.searchInfor.key_number,
        waterfront: this.searchInfor.waterfront,
        water_view: this.searchInfor.water_view,
        walk_to_beach: this.searchInfor.walk_to_beach,
        priority:this.searchInfor.priority,
        hot_tub_or_spa: this.searchInfor.hot_tub_or_spa,
      };
    },
  },
  components: { ShareListingsDialog, PropertiesSearch, PropertiesTable },
  methods: {
    showAllPage() {
       this.currentPage = 1;
       this.limit = this.total
        window.scrollTo(0,0)
        this.getListings();
    },
    load() {
      if(!this.loading){
      if (this.nomore && !this.loaded) return; //到达底部不再执行
      this.currentPage += 1;
      this.getListings();
      }
    },
    pagesChanged(number) {
      this.currentPage = number;
      window.scrollTo(0, 0);
      this.getListings();
    },
    // 重置
    reset() {
      this.currentPage = 1;
      let obj = {
        show_rates: true,
      };
      this.limit = 25;
      this.ordering = "not_renting_year,priority,-calendar_updated_at";
      this.data = [];
      this.searchInfor = Object.assign({}, obj);
      this.$store.commit("setPropertiesChecked", []);
      this.getListings();
    },
    capacityFilter(val) {
      this.currentPage = 1;
      let obj = {
        capacity_gte: val,
      };
      this.limit = 25;
      this.$store.commit("setPropertiesChecked", []);
      this.searchInfor = Object.assign({}, this.searchInfor, obj);
      this.data = [];
      this.getListings();
    },
    consecutiveFilter(val) {
      this.currentPage = 1;
      let obj = {
        nights_count: val,
      };
      this.limit = 25;
      this.$store.commit("setPropertiesChecked", []);
      this.searchInfor = Object.assign({}, this.searchInfor, obj);
      this.data = [];
      this.getListings();
    },
    amenitiesFilter(val) {
      this.currentPage = 1;
      let obj = {
        ac_type: val.ac_type.join(),
        pool_type: val.pool_type.join(),
        pet_allow: val.pet_allow.join(),
        walk_to_beach: val.walk_to_beach,
        water_view: val.water_view,
        waterfront: val.waterfront
      };
      this.limit = 25;
      this.$store.commit("setPropertiesChecked", []);
      this.searchInfor = Object.assign({}, this.searchInfor, obj);
      this.data = [];
      this.getListings();
    },
    bedroomFilter(val) {
      this.currentPage = 1;
      let obj = {
        bedroom_num_gte: val,
      };
      this.limit = 25;
      this.$store.commit("setPropertiesChecked", []);
      this.searchInfor = Object.assign({}, this.searchInfor, obj);
      this.data = [];
      this.getListings();
    },
    dateFilter(date) {
      this.currentPage = 1;
      this.limit = 25;
      this.ordering = "-exist_price";
      let obj = {};
      if (date != null && date.length != 0) {
        obj = { start_date: date[0], end_date: date[1], };
      } else {
        obj = { start_date: null, end_date: null, };
      }
      this.$store.commit("setPropertiesChecked", []);
      this.searchInfor = Object.assign({}, this.searchInfor, obj);
      this.data = [];
      this.getListings();
    },
    lastUpdateFilter(date) {
      this.currentPage = 1;
      this.limit = 25;
      this.ordering = "-exist_price";
      let obj = {};
      if (date != null && date.length != 0) {
        obj = { last_update: date };
      } else {
        obj = { last_update: null };
      }
      this.$store.commit("setPropertiesChecked", []);
      this.searchInfor = Object.assign({}, this.searchInfor, obj);
      this.data = [];
      this.getListings();
    },
    searchFilter(val) { //过滤
      this.currentPage = 1;
      this.limit = 25;
      this.$store.commit("setPropertiesChecked", []);
      this.searchInfor = Object.assign({},this.searchInfor ,val);
      this.data = [];
      this.$refs.topSearch.clear()
      this.getListings();
    },
    searchFilterMove(val){// 过滤 move
      this.currentPage = 1;
      this.limit = 25;
      this.$store.commit("setPropertiesChecked", []);
      this.searchInfor = Object.assign({}, this.searchInfor, val);
      this.data = [];
      this.$refs.topSearch.clear()
      this.getListings();
    },
    addressFilter(val) {
      this.limit = 25;
      this.currentPage = 1;
      let obj = { address: val };
      this.searchInfor = Object.assign({}, this.searchInfor, obj);
      this.data = [];
      this.$store.commit("setPropertiesChecked", []);
      this.getListings();
    },
    areaFilter(val) {
      this.limit = 25;
      this.currentPage = 1;
      let obj = { area: val };
      this.searchInfor = Object.assign({}, this.searchInfor,obj );
      this.data = [];
      this.$store.commit("setPropertiesChecked", []);
      this.getListings();
    },
    keyNumberFilter(val) {
      this.limit = 25;
      this.currentPage = 1;
      let obj = { key_number: val };
      this.searchInfor = Object.assign({}, this.searchInfor, obj);
      this.data = [];
      this.$store.commit("setPropertiesChecked", []);
      this.getListings();
    },
    ownerFilter(val) {
      this.limit = 25;
      this.currentPage = 1;
      let obj = { owner_last_name: val };
      this.searchInfor = Object.assign({}, this.searchInfor, obj);
      this.data = [];
      this.$store.commit("setPropertiesChecked", []);
      this.getListings();
    },
    priceMax(price) {
      this.limit = 25;
      this.currentPage = 1;
      let obj = {
        max_price: price,
      };
      this.searchInfor = Object.assign({}, this.searchInfor, obj);
      this.data = [];
      this.$store.commit("setPropertiesChecked", []);
      this.getListings();
      // this.urldata(this.queryData);
    },
    priceMin(price) {
      this.limit = 25;
      this.currentPage = 1;
      let obj = {
        min_price: price,
      };
      this.searchInfor = Object.assign({}, this.searchInfor, obj);
      this.data = [];
      this.$store.commit("setPropertiesChecked", []);
      this.getListings();
    },
    priceChange(minPrice, maxPrice) {
      this.limit = 25;
      this.currentPage = 1;
      let obj = {
        min_price: minPrice,
        max_price: maxPrice != 30000 ? maxPrice : null,
      };
      this.searchInfor = Object.assign({}, this.searchInfor, obj);
      this.data = [];
      this.$store.commit("setPropertiesChecked", []);
      this.getListings();
    },
    // 排序
    sort(val) {
      this.limit = 25;
      this.currentPage = 1;
      if (val.order === "ascending") {
        this.ordering = "not_renting_year,"+val.prop;
      } else if (val.order === "descending") {
        this.ordering = "not_renting_year,"+"-" + val.prop;
      } else {
        // ordering: "calendar_updated_at", // 排序
        this.ordering = "not_renting_year,priority,-calendar_updated_at";
      }
      this.data = [];
      this.$store.commit("setPropertiesChecked", []);
      this.getListings();
    },
    // 移除未勾选的房子
    subset(val) {
      if (val.length !== 0) {
        this.data = val;
      }
    },
    // 未激活的房子
    activateChange(val) {
      this.limit = 25;
      this.currentPage = 1;
      let obj = { show_inactive: val };
      this.searchInfor = Object.assign({}, this.searchInfor, obj);
      this.getListings();
    },
    showWithoutPrice(val) {
      this.limit = 25;
      this.currentPage = 1;
      let obj = { show_without_price: val };
      this.searchInfor = Object.assign({}, this.searchInfor, obj);
      this.getListings();
    },
    // 分享房屋给 Contact
    shareListings() {
      this.$refs.shareListingsDialog.show = true;
    },
    // 获取房屋列表
    getListings() {
      this.loading = true;
      this.urldata(this.query);
      apiGetListings(validateObjectNull(this.query))
        .then((res) => {
          let arr = res.results;
           if (res.next == null) {
            this.loaded = false;
            this.nomore = true; //没有更多
            this.$message.warning("All has been loaded.");
          }
          if(res.previous===null){
            this.total = res.count;
          }
          this.$store.commit("setPropertiesFilter", this.queryData);
          this.data = [...this.data, ...arr];
          let obj = {};
          this.data = this.data.reduce((cur,next) => {
              obj[next.listing_id] ? "" : obj[next.listing_id] = true && cur.push(next);
              return cur;
          },[]) //设置cur默认类型为数组，并且初始值为空的数组
          }).catch((err) => {
          this.$message.error(
            err.response.data.detail ? err.response.data.detail : null
          );
        }).finally(() => {
          // this.$refs.properFlag.closeFlag();
          this.flag = true;
          if(this.$refs.topSearch) this.$refs.topSearch.popoverShow = false;
          this.loading = false;
        });
    },
    urldata(val) {
      let data = Object.assign({}, validateObjectNull(val));
      delete data.limit;
      delete data.offset;
      delete data.show_rates;
      this.$router.push({ path: "/properties", query: data });
    },
  },
  created() {
    if (JSON.stringify(this.$route.query) != "{}") {
      if(this.$route.query.pet_allow=="true"){
         this.$route.query.pet_allow = true
      }else if(this.$route.query.pet_allow=="false"){
        this.$route.query.pet_allow = false;
      }
      this.ordering = this.$route.query.ordering?this.$route.query.ordering:"not_renting_year,priority,-calendar_updated_at";
      this.searchInfor = this.$route.query;
    }
  },
  
};
</script>
<style lang="scss" scoped>
@import "../../css/common.scss";
@import "../../css/dialog.scss";
@import "../../css/button.scss";
.properties-box {

  .show-page {
    text-align: left;
  }
}
</style>
